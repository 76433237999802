import { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { Button, Row, Col, Space, Grid } from 'antd';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { useInView } from 'framer-motion';
import posthog from 'posthog-js';

import Notifications from 'components/Notifications';
import OnboardingChecklist from 'components/OnboardingChecklist';
import WaitlistModal from 'components/WaitlistModal';

import BurgerMenu from './Menu';

import { useLogoState } from 'context/LogoContext';
import { capitalizeFirstLetter } from 'utils/strings';
import { useMenu } from 'context/MenuContext';

import theme from 'styles/theme';
import { BurgerButton, BurgerLine, HeaderContainer, Logo } from './styles';

function Header() {
  const { data: session } = useSession();
  const screens = Grid.useBreakpoint();
  const router = useRouter();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState('Join the waitlist');
  const [activeItem, setActiveItem] = useState(
    capitalizeFirstLetter(router.route.split('/')[1])
  );
  const { isMenuOpen, setIsMenuOpen } = useMenu();
  const [previousDarkBackground, setPreviousDarkBackground] =
    useState<boolean>(true);

  const { isDarkBackground, setIsDarkBackground, isSolidBackground } =
    useLogoState();

  const isAuthFlow = [
    '/auth/apply',
    '/auth/login',
    '/auth/register',
    '/auth/2fa',
    '/auth/onboarding',
    '/auth/confirm-email',
    '/auth/reset-password',
    '/auth/forgot-password',
    '/auth/confirmation',
  ].includes(router.route);

  const isLoggedInAuthFlow = [
    '/auth/2fa',
    '/auth/onboarding',
    '/auth/confirm-email',
    '/auth/reset-password',
  ].includes(router.route);

  const isMainRoute = [
    '/dashboard',
    '/portfolio',
    '/opportunities',
    '/account',
    '/referrals',
  ].includes(router.route);

  useEffect(() => {
    if (localStorage.getItem('Shuttle-waitlistId')) {
      setWaitlistCtaCopy('View waitlist status');
    }
  }, []);

  useEffect(() => {
    const nextPath = capitalizeFirstLetter(router.route.split('/')[1]);
    if (nextPath !== activeItem) {
      setActiveItem(nextPath);
    }
  }, [router.route, activeItem]);

  useEffect(() => {
    if (isMenuOpen) {
      setPreviousDarkBackground(isDarkBackground);
      setIsDarkBackground(false);
    } else {
      setIsDarkBackground(previousDarkBackground);
    }
  }, [isMenuOpen]);

  const renderHamburger = () => {
    return (
      <BurgerButton
        $isOpen={isMenuOpen}
        $isDarkBackground={isDarkBackground}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
        aria-label="Toggle menu"
      >
        <BurgerLine $isOpen={isMenuOpen} $isDarkBackground={isDarkBackground} />
        <BurgerLine $isOpen={isMenuOpen} $isDarkBackground={isDarkBackground} />
        <BurgerLine $isOpen={isMenuOpen} $isDarkBackground={isDarkBackground} />
      </BurgerButton>
    );
  };

  const isMobile = screens.md === false;

  if (router.route.includes('/auth/logout')) {
    return null;
  }

  return (
    <>
      {!session && (
        <WaitlistModal
          isOpen={waitlistModalOpen}
          setIsOpen={setWaitlistModalOpen}
          onComplete={() => {}}
        />
      )}
      <BurgerMenu isAuthFlow={isAuthFlow} />
      <HeaderContainer
        ref={containerRef}
        $isInView={isInView}
        $isHome={router.route === '/'}
        $isSolidBackground={isSolidBackground}
        $isDarkBackground={isDarkBackground}
        $isMobile={isMobile}
      >
        <Row
          style={{
            padding: screens.md ? '30px 60px 25px' : '30px 30px 15px',
            position: 'relative',
            margin: '0 auto',
            alignItems: 'center',
          }}
          justify="space-between"
        >
          <Col>
            <Link href={session ? '/dashboard' : '/'}>
              <Logo
                src="/images/shuttle-icon.svg"
                loading="eager"
                width={240}
                height={338}
                alt="Logo"
                $isDarkBackground={isDarkBackground}
              />
            </Link>
          </Col>
          <Col>
            <Row justify="end" align="bottom">
              {session && !isAuthFlow && (
                <Space size={30}>
                  <Space size={15}>
                    {isMainRoute && (
                      <>
                        <OnboardingChecklist />
                        <Notifications />
                      </>
                    )}
                  </Space>
                  {renderHamburger()}
                </Space>
              )}
              {session && isLoggedInAuthFlow && (
                <Button
                  type="primary"
                  onClick={() => {
                    signOut({ callbackUrl: '/auth/login' });
                    posthog.capture('auth_flow_sign_out_click');
                  }}
                >
                  Sign out
                </Button>
              )}
              {!session && !isAuthFlow && (
                <Space size={isMobile ? 15 : 30}>
                  {/* <Link href="/auth/register">
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: isMenuOpen && theme.colors.white,
                      }}
                      onClick={() => {
                        // setWaitlistModalOpen(true);
                        posthog.capture('header_register_cta_click');
                      }}
                    >
                      Join the club
                    </Button>
                  </Link> */}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: isMenuOpen && theme.colors.white,
                    }}
                    onClick={() => {
                      setWaitlistModalOpen(true);
                      posthog.capture('header_waitlist_cta_click');
                    }}
                  >
                    {waitlistCtaCopy}
                  </Button>
                  {renderHamburger()}
                </Space>
              )}
            </Row>
          </Col>
        </Row>
      </HeaderContainer>
    </>
  );
}

export default Header;
