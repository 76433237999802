import { memo, useEffect } from 'react';
import { Tooltip, Grid, Checkbox, Form, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { useQuery } from '@apollo/client';

import { PITCHEDIT_FEES } from 'config';

import { GET_REFERRALS_QUERY } from 'queries';

// import { MandateInput } from 'types/opp';
import { ReferralProps } from 'types';

// import DirectDebit from './DirectDebit';

import useUserData from 'hooks/useUserData';

import { currencyFormatter } from 'utils/strings';
import { flattenEntities } from 'utils/graphql';
import { calculateTotalPrice } from 'containers/Auth/Onboarding/Investor/utils';
import generatePDF from './generatePDF';

import theme from 'styles/theme';
import {
  HeaderRow,
  StyledTitle,
  ContentDivider,
  SummaryRow,
  MobileText,
  StrikethroughTitle,
  ProcessorFeeTitle,
  CheckboxRow,
  StyledLink,
  StyledTitleValue,
  SummaryTitle,
  SummaryValue,
} from './styles';

export interface PaymentMethodProps {
  form: FormInstance;
  paymentType: 'annual' | 'quarterly' | 'annualQuarterly';
}

function PaymentMethod({ form, paymentType }: PaymentMethodProps) {
  const screens = Grid.useBreakpoint();

  const { userData } = useUserData();

  const { data: referralData } = useQuery(GET_REFERRALS_QUERY, {
    skip: !userData,
    variables: {
      filters: {
        user: { id: { eq: userData?.id } },
      },
    },
  });
  const referral: ReferralProps = flattenEntities(referralData?.referrals)
    ?.data[0];

  useEffect(() => {
    const generateFile = async () => {
      const file = await generatePDF(
        `${userData.accountDetails.firstName} ${userData.accountDetails.lastName}`,
        userData.id
      );
      form.setFieldValue(['investorDetails', 'investmentTermsDocument'], file);
    };
    generateFile();
  }, []);

  const isMobile = screens.sm === false;

  const total = calculateTotalPrice(paymentType, userData, referral);

  return (
    <>
      <HeaderRow>
        <StyledTitle $noMargin={!isMobile} level={4} style={{ marginTop: 0 }}>
          Payment method
        </StyledTitle>
        <StyledTitleValue level={4}>
          SEPA Bank Transfer{/* SEPA Direct Debit */}
        </StyledTitleValue>
      </HeaderRow>
      <ContentDivider />
      {(paymentType === 'annual' || paymentType === 'annualQuarterly') && (
        <SummaryRow $isMobile={!isMobile}>
          <MobileText $isMobile={!isMobile}>
            Shuttle membership (1 year)
          </MobileText>
          <Space>
            <StrikethroughTitle level={4} type="secondary">
              €500
            </StrikethroughTitle>
            {!referral && (
              <StyledTitleValue
                type={referral ? 'secondary' : null}
                level={4}
                style={{
                  textDecoration: referral ? 'line-through' : 'none',
                }}
              >
                {currencyFormatter(
                  userData.role.name === 'Super Admin' ? 1 : PITCHEDIT_FEES
                )}
              </StyledTitleValue>
            )}
            {referral && (
              <Tooltip
                title="10% referral discount 🎉"
                placement="topRight"
                autoAdjustOverflow={false}
                overlayInnerStyle={{
                  fontSize: 14,
                  padding: '5px 10px',
                  minHeight: 26,
                  width: 255,
                }}
              >
                <StyledTitleValue level={4}>
                  {currencyFormatter(
                    userData.role.name === 'Super Admin'
                      ? 1
                      : PITCHEDIT_FEES - referral.payout
                  )}
                </StyledTitleValue>
              </Tooltip>
            )}
          </Space>
        </SummaryRow>
      )}
      {(paymentType === 'quarterly' || paymentType === 'annualQuarterly') && (
        <SummaryRow $isMobile={!isMobile}>
          <MobileText $isMobile={!isMobile}>Quarterly Investment</MobileText>
          <StyledTitleValue level={4}>
            {currencyFormatter(
              userData.investorDetails.quarterlySubscription || 250
            )}
          </StyledTitleValue>
        </SummaryRow>
      )}
      <SummaryRow $isMobile={!isMobile}>
        <MobileText $isMobile={!isMobile}>Payment processer fee</MobileText>
        <ProcessorFeeTitle level={4}>Free</ProcessorFeeTitle>
      </SummaryRow>
      {/* <StyledTitle level={4}>Bank details</StyledTitle>
      <ContentDivider />
      <DirectDebit /> */}
      <StyledTitle level={4} $noMargin={false}>
        Summary
      </StyledTitle>
      <ContentDivider />
      <SummaryRow
        $isMobile={!isMobile}
        style={{ marginBottom: userData.investmentTerms ? 40 : 20 }}
      >
        <SummaryTitle level={5}>Total due now</SummaryTitle>
        <SummaryValue level={4}>{currencyFormatter(total)}</SummaryValue>
      </SummaryRow>
      <CheckboxRow $isMobile={isMobile}>
        <Form.Item
          name="oppTerms"
          valuePropName="checked"
          style={{ margin: '-25px 0 0 0' }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('You must agreee to the OPP User Terms')
                    ),
            },
          ]}
        >
          <Checkbox style={{ color: theme.colors.bodyText }}>
            I agree to the OPP{' '}
            <StyledLink
              href="https://6097154.fs1.hubspotusercontent-eu1.net/hubfs/6097154/Terms%20and%20Policies/EN/User%20Terms%20OPP/opp-user-terms-version-04112022.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              User Terms
            </StyledLink>{' '}
            (our payments partner)
          </Checkbox>
        </Form.Item>
        {!userData.investmentTerms && (
          <>
            <Form.Item
              name={['investorDetails', 'investmentTerms']}
              style={{ margin: isMobile ? '0 0 15px 0' : '-10px 0 0 0' }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'You must agreee to the Shuttle Investment Terms & Conditions'
                          )
                        ),
                },
              ]}
            >
              <Checkbox style={{ color: theme.colors.bodyText }}>
                I agree to the Shuttle{' '}
                <Typography.Link
                  onClick={async (e) => {
                    e.preventDefault();
                    const file = form.getFieldValue([
                      'investorDetails',
                      'investmentTermsDocument',
                    ]);
                    window.open(URL.createObjectURL(file), '_blank');
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  Investment Terms & Conditions
                </Typography.Link>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['investorDetails', 'abilityToBearLoss']}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'You must acknowledge you understand the risk of loss of funds'
                          )
                        ),
                },
              ]}
            >
              <Checkbox style={{ color: theme.colors.bodyText }}>
                I acknowledge that any investment I make through this platform
                carries the risk of losing the entirety of the money invested
              </Checkbox>
            </Form.Item>
          </>
        )}
      </CheckboxRow>
    </>
  );
}

export default memo(PaymentMethod);
