import { useRef, useState, useEffect } from 'react';
import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
import Head from 'next/head';
import { useQuery } from '@apollo/client';
import { useInView } from 'framer-motion';
import { Grid } from 'antd';
import posthog from 'posthog-js';

import {
  COOKIE_BANNER_QUERY,
  HOME_PAGE_QUERY,
  GET_HOMEPAGE_RESOURCES_QUERY,
  GET_HOMEPAGE_FAQ_QUERY,
  WAITLIST_PAGE_QUERY,
  GET_HOMEPAGE_ANNOUNCEMENT_QUERY,
} from 'queries';

import Hero from 'components/Home/Hero';
import Video from 'components/Home/Video';
import SADInvesting from 'components/Home/SADInvesting';
import Performance from 'components/Home/Performance';
import Redefined from 'components/Home/Redefined';
import FAQ from 'components/Home/FAQ';
import Footer from 'components/Footer';
import Education from 'components/Home/Education';
import AnnouncementModal from 'components/Home/AnnouncementModal';

import { initializeApollo } from 'lib/apollo/client';

import { redirectIfAuthorised } from 'utils/auth';

import theme from 'styles/theme';
import { PageContainer, PageSection } from './styles';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession({ req: ctx.req });

  const shouldRedirect = redirectIfAuthorised(session, ctx.resolvedUrl);
  if (shouldRedirect) return shouldRedirect;

  const { query, cache } = initializeApollo();

  cache.evict({ fieldName: 'cookiePolicyModal' });
  cache.evict({ fieldName: 'homePage' });
  cache.evict({ fieldName: 'waitlistPage' });
  cache.evict({ fieldName: 'resources' });
  cache.evict({ fieldName: 'faqs' });
  cache.gc();

  const [cookies] = await Promise.all([
    query({ query: COOKIE_BANNER_QUERY }),
    query({ query: HOME_PAGE_QUERY }),
    query({ query: WAITLIST_PAGE_QUERY }),
    query({ query: GET_HOMEPAGE_RESOURCES_QUERY }),
    query({ query: GET_HOMEPAGE_ANNOUNCEMENT_QUERY }),
    query({ query: GET_HOMEPAGE_FAQ_QUERY }),
  ]);

  return {
    props: {
      cookieBanner: cookies.data.cookiePolicyModal.data.attributes,
      initialApolloState: cache.extract(),
    },
  };
};

export interface HomePageProps {
  copy: any;
}

export default function HomePage() {
  const screens = Grid.useBreakpoint();
  const { data: pageData } = useQuery(HOME_PAGE_QUERY);
  const copy: HomePageProps['copy'] = pageData?.homePage.data.attributes.copy;

  const [backgroundColor, setBackgroundColor] = useState(theme.colors.darkTeal);

  const heroRef = useRef(null);
  const videoRef = useRef(null);
  const sadInvestingRef = useRef(null);
  const performanceRef = useRef(null);
  const redefinedRef = useRef(null);
  const educationRef = useRef(null);
  const faqRef = useRef(null);

  const isMobile = screens.md === false;

  const heroInView = useInView(heroRef, { amount: 0.5 });
  const videoInView = useInView(videoRef, { amount: 0.5 });
  const sadInvestingInView = useInView(sadInvestingRef, {
    amount: isMobile ? 0.1 : 0.5,
  });
  const performanceInView = useInView(performanceRef, { amount: 0.5 });
  const redefinedInView = useInView(redefinedRef, { amount: 0.5 });
  const faqInView = useInView(faqRef, { amount: isMobile ? 0.1 : 0.5 });
  const educationInView = useInView(educationRef, { amount: 0.5 });

  const [viewedSections, setViewedSections] = useState(new Set<string>());

  useEffect(() => {
    const trackSectionView = (section: string) => {
      if (!viewedSections.has(section)) {
        posthog.capture('homepage_section_viewed', {
          section_name: section,
          page: 'homepage',
        });
        setViewedSections((prev) => new Set(Array.from(prev).concat(section)));
      }
    };

    if (heroInView) {
      setBackgroundColor(theme.colors.darkTeal);
      trackSectionView('homepage_hero');
    } else if (videoInView) {
      setBackgroundColor(theme.colors.white);
      trackSectionView('homepage_video');
    } else if (sadInvestingInView) {
      setBackgroundColor(theme.colors.white);
      trackSectionView('homepage_sad_investing');
    } else if (performanceInView) {
      setBackgroundColor('#001b1d');
      trackSectionView('homepage_performance');
    } else if (redefinedInView) {
      setBackgroundColor(theme.colors.white);
      trackSectionView('homepage_redefined');
    } else if (educationInView) {
      setBackgroundColor(theme.colors.white);
      trackSectionView('homepage_education');
    } else if (faqInView) {
      setBackgroundColor(theme.colors.lightGrey);
      trackSectionView('homepage_faq');
    }
  }, [
    heroInView,
    videoInView,
    sadInvestingInView,
    performanceInView,
    redefinedInView,
    faqInView,
    educationInView,
    viewedSections,
  ]);

  return (
    <>
      <Head>
        <title key="title">Shuttle</title>
        <meta
          property="og:url"
          content={process.env.NEXTAUTH_URL}
          key="og:url"
        />
      </Head>
      <PageContainer
        $backgroundColor={backgroundColor}
        $isMobile={isMobile}
        id="Home-mainContainer"
      >
        {/* <AnnouncementModal /> */}
        <PageSection ref={heroRef}>
          <Hero copy={copy.hero} />
        </PageSection>
        <PageSection ref={sadInvestingRef}>
          <SADInvesting copy={copy.sadInvesting} />
        </PageSection>
        <PageSection ref={performanceRef}>
          <Performance copy={copy.performance} />
        </PageSection>
        <PageSection ref={redefinedRef}>
          <Redefined copy={copy.redefined} />
        </PageSection>
        <PageSection ref={videoRef}>
          <Video copy={copy.video} />
        </PageSection>
        <PageSection ref={educationRef}>
          <Education copy={copy.education} />
        </PageSection>
        <PageSection ref={faqRef}>
          <FAQ copy={copy.faq} />
        </PageSection>
        <PageSection ref={faqRef}>
          <Footer $homepage />
        </PageSection>
      </PageContainer>
    </>
  );
}
