import * as R from 'ramda';
import { subDays, isBefore, parseISO } from 'date-fns';
import type { Session } from 'next-auth';
import { signOut } from 'next-auth/react';

import { UserProps } from 'types/user';

import { fetchGetJSON } from 'utils/apiHelpers';

const twoFactorExpired = (lastAuthenticated) => {
  if (!lastAuthenticated) {
    return true;
  }

  const thirtyDaysAgo = subDays(new Date(), 30);
  return isBefore(parseISO(lastAuthenticated), thirtyDaysAgo);
};

export const redirectIfUnauthorised = async (
  session: Session,
  origin: string
) => {
  const query = `?origin=${origin}`;

  if (R.isNil(session)) {
    return {
      redirect: {
        destination: `/auth/login${origin ? query : ''}`,
        permanent: false,
      },
    };
  }

  let userData: UserProps;
  try {
    console.log('AUTH REDIRECT CHECK - Fetching user data');
    userData = await fetchGetJSON(
      `${process.env.BACKEND_URL}/api/users/me?populate[0]=twoFactorAuth&populate[1]=role`,
      session.jwt
    );
    console.log('AUTH REDIRECT CHECK - User data fetched', {
      session,
      userData,
    });
  } catch (error) {
    console.error('AUTH REDIRECT CHECK - Error fetching user data', error);
    return {
      redirect: {
        destination: '/auth/logout',
        permanent: false,
      },
    };
  }

  if (!userData?.id) {
    console.log('AUTH REDIRECT CHECK - REDIRECTING TO LOGIN', {
      sessionId: session.user.id,
      userDataId: userData?.id,
    });
    return {
      redirect: {
        destination: '/auth/logout',
        permanent: false,
      },
    };
  }

  if (userData?.confirmed !== true && !origin?.includes('confirm-email')) {
    console.log('AUTH REDIRECT CHECK - REDIRECTING TO CONFIRM EMAIL');
    return {
      redirect: {
        destination: '/auth/confirm-email',
        permanent: false,
      },
    };
  }

  if (R.isNil(userData?.persona) && !origin?.includes('onboarding')) {
    console.log('AUTH REDIRECT CHECK - REDIRECTING TO ONBOARDING');
    return {
      redirect: {
        destination: '/auth/onboarding',
        permanent: false,
      },
    };
  }

  if (
    userData?.twoFactorAuth?.enabled &&
    !origin?.includes('2fa') &&
    twoFactorExpired(userData?.twoFactorAuth?.lastAuthenticated)
  ) {
    console.log('AUTH REDIRECT CHECK - REDIRECTING TO 2FA');
    return {
      redirect: {
        destination: '/auth/2fa',
        permanent: false,
      },
    };
  }

  return null;
};

export const redirectIfAuthorised = (session, origin) => {
  const query = `?origin=${origin}`;

  if (session) {
    return {
      redirect: {
        destination: `/dashboard${origin && origin !== '/' ? query : ''}`,
        permanent: false,
      },
    };
  }

  return null;
};

export const hasPremiumAccess = (user) => {
  return true;
};
